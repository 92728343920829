.home-cover {
  // position: relative;
  // background-image: url(/images/eagle.jpg);
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: cover;
}

.home-cover-mask {
  // background: $base-color;
  // background: linear-gradient(
  //     180deg,
  //     rgba(0, 49, 83, 0.7) 0%,
  //     rgba(0, 49, 83, 0) 100%
  // );
}

.button-zoom {
  transform: scale(1);
  transition: all 0.2s ease-out;

  &:hover {
    transform: scale(1.05);
  }
}

.home-first-impression {
  margin-top: 1em;
  font-size: 1.8em;
  text-align: center;
  font-family: $headings-home-font-family;
}

.text-align-left {
  text-align: left;
}

.home-header {
  font-family: "Roboto Condensed", $font-family-base;

  h2,
  h3 {
    font-size: 1.4em;
    font-family: $headings-home-font-family;

    @include media-breakpoint-up(lg) {
      font-size: 3em;
    }
  }
}

.home-header-top {
  height: 90vh;
  min-height: 220px;
  margin-bottom: 10px;
  color: $white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.btn.btn-rounded {
  border-radius: 34px;
}

.home-ticker {
  font-family: "Erbos Draco", $font-family-base;
  font-size: 1.5em;
  line-height: 1;
  text-transform: uppercase;

  span {
    white-space: nowrap;
    display: inline-block;
    padding: 8px 16px 10px;
    color: #ffffff;

    > span {
      display: inline;
      padding: 0;
    }

    &.high {
      color: #00cc00;
    }

    &.low {
      color: #ff0000;
    }
  }
}

.home-ticker-dispersion {
  font-family: $headings-font-family, $font-family-base;
  font-size: 1.75em;
  line-height: 1;
  text-transform: uppercase;

  span {
    white-space: nowrap;
    display: inline-block;
    padding: 5px 10px;
  }

  div > span {
    display: block;
  }
}

.home-ticker-indices {
  font-family: $headings-font-family, $font-family-base;
  font-size: 1.5em;
  line-height: 1;
  text-transform: uppercase;

  span {
    white-space: nowrap;
    display: inline-block;
    padding: 8px 16px 10px;
  }

  div > span {
    display: block;
  }
}

.home-header-bottom-divider {
  // border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.home-header-content-cell {
  position: relative;
  z-index: 900;
}

.home-header-content-mask {
  position: absolute;
  display: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  &:before {
    display: block;
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}

.home-header-content {
  position: relative;
  z-index: 100;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  // padding: 50px 50px;
  color: $white;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.8);

  &.home-header-content--part1 {
    background-image: url("/images/header_cover.jpg");

    .home-header-content-mask {
      display: block;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.5) 40%,
        rgba(0, 0, 0, 0.1) 100%
      );
    }
  }

  &.home-header-content--part1b {
    background-image: none;
    background-color: $white;
    color: $black;
    text-shadow: none;

    .home-header-content-mask {
    }
  }

  &.home-header-content--part2 {
    background-image: url(/images/homebg-1a-min.jpg);

    .home-header-content-mask {
      display: block;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.2) 100%
      );
    }
  }

  &.home-header-content--part3 {
    background-image: url(/images/homebg-1b-min.jpg);

    .home-header-content-mask {
      display: block;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.6) 100%
      );
    }
  }

  &.home-header-content--part3a {
    background-image: url(/images/homebg-2-min.jpg);

    .home-header-content-cell {
      padding-top: 60px;
      padding-bottom: 70px;
    }

    .home-header-content-mask {
      display: block;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 40%,
        rgba(255, 255, 255, 0.4) 100%
      );
    }
  }

  &.home-header-content--part6 {
    background-image: url(/images/eagle.jpg);

    .home-header-content-mask {
      display: block;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0.3) 30%,
        rgba(0, 0, 0, 0.3) 70%,
        rgba(0, 0, 0, 0.7) 100%
      );
    }
  }
}

.home-header-title {
  display: block;
  font-family: $headings-home-font-family;
  font-size: 1.75em;
  margin-bottom: 0px;
  letter-spacing: 2px;
  line-height: 1.4;

  // &:after {
  //     display: block;
  //     content: "";
  //     position: relative;
  //     height: 5px;
  //     width: 100%;
  //     background-color: $white;
  // }

  @include media-breakpoint-up(lg) {
    font-size: 2.5em;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 3em;
  }
}

.home-box {
  padding: 10px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;

  background-color: rgba(255, 255, 255, 0.4);

  transition: all 0.3s ease-out;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);

  .home-box-title {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }

  .home-box-text {
    font-size: 1.1em;
  }

  &:hover {
  }

  &.home-box-1 {
    // background-color: #135742;
    // color: $white;
  }

  &.home-box-2 {
    // background-color: #0b4a6e;
    // color: $white;
  }
}

.modal.home-disclaimer-modal .modal-lg {
  max-width: 650px;
}

.modal.home-disclaimer-modal .modal-footer {
  background-color: #e6e6e6;
}

.modal.home-disclaimer-modal .modal-body {
  max-height: 350px;
  padding: 35px;
}

.title-highlight {
  color: $primary-light;
  text-transform: uppercase;
}

.text-highlight {
  color: $primary-light;
}

.text-light-highlight {
  color: #fc0;
}

.home-highlight-box {
  display: inline-block;
  padding: 10px;
  margin: 10px 0 20px;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: $box-shadow-primary;
}

.img-home {
  margin-right: 25px;
  width: 110px;
  height: auto;
}

.home-list-icons {
  margin: 20px auto 30px;
  max-width: 260px;
  height: 200px;

  &.home-list-icons--left {
  }

  &.home-list-icons--center {
  }

  &.home-list-icons--right {
  }
}

.home-button-action {
  box-shadow: 0 0 5px 5px rgba(255, 255, 255, 0.6);
}

.home-box-text-simple1 {
  background-color: #eee;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 30px;
  padding: 5px 20px;
  text-align: center;
}

.home-box-text-simple2 {
  position: relative;
  margin: 0 auto;
  color: $primary-light;
  display: inline-block;
  border-radius: 30px;
  margin-left: 3px;
  text-align: center;
  text-transform: uppercase;

  &:before {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-color: #2a6fc4;
  }
}

.heroTable {
  background-color: transparent;
  width: 100%;

  &__headers {
    background-color: #194b88;
    color: #000000;
    & th {
      border: solid 1px white;
      padding: 5px;
      border-bottom: 4px solid white;
      color: white;
    }
  }
  & tr:nth-child(odd):not(:first-child) {
    background-color: rgba(#194b88, 0.1);
  }
  & tr:nth-child(even):not(:first-child) {
    background-color: rgba(#194b88, 0.3);
  }
  & td {
    border: solid 1px white;
    padding: 5px;
  }
  & .positiveValue {
    color: green;
  }
  & .negativeValue {
    color: red;
  }
}
.topic {
  font-weight: 700;
  color: #194b88;
}

// .unorderedList {
// }

.home-header-content--part2b {
  & .title {
    color: #194b88;
    font-weight: 700;
  }
  & .disclaimer {
    color: #2f2f2f;
  }
}

.positiveValue {
  color: green;
}
.negativeValue {
  color: red;
}

.heroTable {
  font-size: 20px;
}

.ratingA {
  color: green;
}
.ratingB {
  color: #8bbc00;
}
.ratingC {
  color: #f48400;
}
.ratingD {
  color: red;
}

.knowMoreBtn {
  border: solid 1px #ffc001;
  width: fit-content;
  cursor: pointer;
  border-radius: 34px;
}

.home-subtitles-xl {
  font-size: 3em !important;
  text-align: center;
  font-weight: bold;
}

.home-subtitles-m {
  font-size: 1.5em !important;
  text-align: center;
}

.home-section-1 {
  display: flex;
  margin: 2.5em;
  border-radius: 50px;
  overflow: hidden;
  background: #fff;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;

  .home-card-text-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10vw;
    border-radius: 50px;

    p {
      font-size: 1.8em;
    }
  }

  .home-card-image-wrapper {
    flex: 1;
    overflow: hidden;
    -webkit-clip-path: polygon(25% 100%, 100% 100%, 100% 100%, 100% 0%, 0% 0%);
    clip-path: polygon(25% 100%, 100% 100%, 100% 100%, 100% 0%, 0% 0%);
    background-image: url("../images/section-1-home-background.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-position-x: 30vw;
    background-repeat: no-repeat;
    height: 50vh;
  }
}

.home-first-impression-big-margin {
  font-size: 3em;
  margin-top: 1em;
  text-align: center;
  font-family: $headings-home-font-family;
}

.image-page-break {
  border-radius: 50px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #fff;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  background-image: url("../images/section-2-home-background.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  position: relative;

  .last-section-content {
    margin-top: 50vh;
    background-color: white;
    width: 100%;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    padding: 8em;

    p {
      font-size: 1.6em;
    }
  }
}

.image-page-break-last {
  border-radius: 50px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #fff;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  background-image: url("../images/section-3-home-background.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  position: relative;

  .last-section-content {
    margin-top: 50vh;
    background-color: white;
    width: 100%;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    padding: 8em;

    p {
      font-size: 1.6em;
    }
  }
}

@media (min-width: 1200px) {
  .image-page-break {
    background-position-y: -20vh;
  }
}

.cta-wrapper {
  padding: 5em 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;

  border-radius: 50px;
  background: #fff;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;

  transition: all 0.5s;

  .cta-text {
    text-align: center;
    font-size: 1.8em;
  }

  .cta-btn {
    min-width: 50%;
    font-size: 1.8em;
    font-weight: 600;
  }
}

.neumorphism-container {
  padding: 4em;
  border-radius: 50px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  height: 100%;
}

.brand-image {
  background-image: url("./../images/logo_bg.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-position: center;
}

.bullet-point-list {
  margin-top: 3em;
  font-size: 1.6em;
}

.faq-title {
  font-size: 1.8em !important;
}

.faq-paragraph {
  font-size: 1.2em !important;
}

.faq-paragraph-big {
  font-size: 1.5em !important;
}

.advantage-box {
  display: flex;
  justify-content: center;

  .advantages-list {
    display: flex;
    margin-top: 1.5em;
    flex-direction: column;
    list-style: none;

    &-item {
      display: flex;
      gap: 10px;
    }

    &-icon {
      font-size: 2.5em;
      color: #00cc00;
    }

    &-title {
      font-size: 2.5em;
      text-transform: uppercase;
    }
  }
}
.advantage-box p {
  font-size: 1.6em;
}

.systematicApproachTable {
  & tr {
    border: solid black 1px;

    & td {
      border-left: #000000 solid 1px;
      border-right: #000000 solid 1px;
      text-align: center;
    }
    .bgGreen{
      color: rgb(4, 159, 4);
      background-color: rgba(0, 128, 0, 0.357);
    }

    .bgRed{
      color: red;
      background-color: rgba(255, 0, 0, 0.357);
    }

  }
}
