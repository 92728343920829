.peerWeightPie {
    background-color: #fff;
    border: 1px solid #f7f7f7;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
}

.peerWeightPie--horizontal {
    display: flex;
    justify-content: space-between;
}

.peerWeightPie-box {
}

.peerWeightPie--horizontal .peerWeightPie-box {
}

.peerWeightPie-title {
    height: 50px;
}

.peerWeightPie-content {
}

.peerWeightPie-chart {
}

.peerWeightPie-tableContainer {
    height: 200px;
    width: 100%;
    overflow: auto;
}

.peerWeightPie--horizontal .peerWeightPie-tableContainer {
    // height: 9.75em;
}

.peerWeightPie-table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
}

.peerWeightPie-itemName {
    font-weight: normal;
    text-align: left;
}

.peerWeightPie-itemNameValue {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
    width: 13em;
}

.peerWeightPie-itemTcr {
    width: 10%;
}

.peerWeightPie-itemWeight {
    padding-right: 1em;
    text-align: right;
    width: 20%;
}

.peerWeightPie-isClickable {
}

.peerWeightPie-isClickable .peerWeightPie-itemNameValue,
.peerWeightPie-isClickable .itemTcr-itemNameValue,
.peerWeightPie-isClickable .itemTcr-itemWeight {
    cursor: pointer;
}

.long-short__histogram__country__labels {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin-right: 10px;

    p {
        margin-bottom: 0;
        margin-right: 5px;
    }

    img {
        transform: translateY(-25%);
        z-index: -1;
    }
}

.tooltip__highcharts {
    background-color: white;
    padding: 5px;
    border: 2px solid;
}
