.page-capture {
    font-size: 0.8em;
    padding-bottom: 40px;

    .capture-selector {
        min-width: 15em;
    }
}

.rate_A {
    text-align: center;
    color: #008000;
    font-weight: bold;
}
.rate_B {
    text-align: center;
    color: #8bbc00;
    font-weight: bold;
}
.rate_C {
    text-align: center;
    color: #f48400;
    font-weight: bold;
}
.rate_D {
    text-align: center;
    color: #f00000;
    font-weight: bold;
}
.rate_NA {
    text-align: center;
    color: black;
    font-weight: bold;
}
.color_A {
    color: #008000;
}
.color_B {
    color: #8bbc00;
}
.color_C {
    color: #f48400;
}
.color_D {
    color: #f00000;
}
.color_NA {
    color: black;
}
