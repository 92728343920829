@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("./fonts/lato400.woff2") format("woff2"),
        url("./fonts/lato400.woff") format("woff");
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: local(""), url("./fonts/lato700.woff2") format("woff2"),
        url("./fonts/lato700.woff") format("woff");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    src: local(""), url("./fonts/inter400.woff2") format("woff2"),
        url("./fonts/inter400.woff") format("woff");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    src: local(""), url("./fonts/inter600.woff2") format("woff2"),
        url("./fonts/inter600.woff") format("woff");
}

@font-face {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("./fonts/robotocondensed400.woff2") format("woff2"),
        url("./fonts/robotocondensed400.woff") format("woff");
}

@font-face {
    font-family: "Bebas";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("./fonts/bebas400.woff2") format("woff2"),
        url("./fonts/bebas400.woff") format("woff");
}


@font-face {
    font-family: "Erbos Draco";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("./fonts/erbos_draco.woff2") format("woff2"),
        url("./fonts/erbos_draco.woff") format("woff");
}
