/* #region ------------------------------------------------- table bar charts */
.product-bars {
    display: inline-block;
    line-height: 0;
    overflow: hidden;
    padding-top: 1px;
    text-align: left;
    width: 50px;
}

.product-bar {
    border-bottom: 1px solid #fff;
    display: inline-block;
    height: 3px;
    vertical-align: middle;
}

.product-bar--empty {
    background-color: #fff;
}

.product-bar--negative {
    background-color: red;
}

.product-bar--positive {
    background-color: #70cc70;
}

.product-bar-container--index
    .product-bar--negative,
.product-bar-container--index
    .product-bar--positive {
    background-color: #2a7092;
}

.product-bar-container--benchmark
    .product-bar--negative,
.product-bar-container--benchmark
    .product-bar--positive {
    background-color: #888;
}
/* #endregion --------------------------------------------------------------- */
