.rate {
    font-weight: 600;
}

.rate--A {
    color: #008000;
}

.rate--B {
    color: #8bbc00;
}

.rate--C {
    color: #f48400;
}

.rate--D {
    color: #f00000;
}

.i-alert,
.rate--downgrade,
.rate--new,
.rate--no-change,
.rate--upgrade {
    color: #fb0;
}

.rate--big {
    font-size: 200%;
    line-height: 0.5em;
}

.rate--pointInTime {
    display: inline-block;
    font-size: 200%;
    margin-left: 0.5em;
}
