@import "./styles/fonts";

$header-height: 56px;
$footer-height: 36px;

$border-radius: 0.05em;
$border-radius-sm: 0.05em;
$border-radius-lg: 0.1em;

$font-family-base: "Inter", sans-serif;
$headings-font-family: "Roboto Condensed", sans-serif;
$headings-home-font-family: "Bebas", $headings-font-family; // Custom
$headings-font-weight: 400;
// $primary: #2066b9;
$base-color: #003153;
$primary: #194b88;
$primary-light: #2a6fc4;

// Override default variables before the import
$body-bg: #fff; // cannot use variable here, bootstrap will be defined later

$box-shadow-primary: 3px 3px 6px rgba(0, 0, 0, 0.4);
$input-btn-focus-width: 0.1em;

//$primary:

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import "~tabulator-tables/src/scss/bootstrap/tabulator_bootstrap4.scss";

@import "~bootstrap-icons/font/bootstrap-icons";

@import "./styles/trendrating.scss";

@import "./styles/trendrating-formatter.scss";

@import "./styles/PeerWeightPie.scss";

@import "./styles/wSecurityAnalysisChart.scss";

@import "./styles/rate.scss";

@import "./styles/home.scss";

@import "./styles/capture.scss";

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable
  .tabulator-col-title {
  padding-right: 10px;
}

.no-separator {
  .tabulator-col-group-cols {
    border-top: none !important;
  }
}

.col-group-custom {
  .tabulator-col-group-cols {
    border-top: none !important;
  }

  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

body {
  &.modal-open {
    @include media-breakpoint-only(xs) {
      overflow: hidden;
      height: 100%;
      height: 100vh;
    }
  }
}

.nav-wrap .nav.nav-pills .nav-link {
  border-top: 5px solid transparent;
  padding-left: 6px;
  padding-right: 6px;

  @include media-breakpoint-up(md) {
    padding-left: 1em;
    padding-right: 1em;
  }
}

// .tabulator .tabulator-header {
//     border-top: 1px solid $base-color;
//     border-bottom: 2px solid $base-color;
// }

// .tabulator.table-bordered {
//     border-color: $primary;
// }

// .tabulator.table-bordered .tabulator-header .tabulator-col {
//     border-color: $base-color;
// }

// .tabulator .tabulator-header .tabulator-col {
//     background-color: $primary;
//     color: $white;
// }

// .tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
//     background-color: $base-color;
//     color: $white;
// }

.smaller-icon {
  font-size: 0.9em;
}

.tabulator.table-striped:not(.table-dark) .tabulator-row:nth-child(odd) {
  background-color: #f6f6f6;
}

.tabulator.table-striped:not(.table-dark)
  .tabulator-row:nth-child(odd).tabulator-selectable:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.nav-wrap {
  background-color: $primary;
}

.nav-wrap .nav {
  &.nav-pills {
    .nav-link {
      background-color: transparent;
      border-radius: 0;
      color: #c3c3c3;
      border-bottom: 5px solid transparent;

      &:hover {
        background-color: $primary-light;
        border-bottom-color: transparent;
        color: $white;
      }

      &.active {
        background-color: transparent;
        border-bottom-color: $primary-light;
        color: $white;
      }
    }

    .show > .nav-link {
      color: $white;
    }
  }

  &.sticky-top {
    box-shadow: $box-shadow-primary;
  }
}

.headerSpace {
  padding-top: $header-height;
}

header {
  position: relative;
  z-index: 990;
  background-color: $black;
  color: $white;
  height: auto;

  @include media-breakpoint-up(md) {
    height: $header-height;
  }
}

footer {
  background-color: $black;
  color: $white;
  position: relative;
  z-index: 200;
}

main {
  background-color: #f0f0f0;
  min-height: 0;

  &.main-no-bg {
    min-height: 0;
    background-color: transparent;
  }

  &.fillHeight {
    min-height: 0;
    overflow: hidden;
    height: 100%;
  }

  @include media-breakpoint-up(sm) {
    min-height: calc(100vh - #{$header-height} - #{$footer-height});
  }
}

.fillHeight {
  min-height: 0;
  overflow: hidden;
  height: 100%;
}

.head-content {
  padding-top: 2em;
  background-color: $base-color;
  color: $white;
  box-shadow: $box-shadow-primary;
  z-index: 20;

  .nav {
    margin-top: 2em;
  }
}

.head-content.sticky-top {
  position: relative;

  @include media-breakpoint-up(sm) {
    position: static; /* IE11 */
    position: sticky;
    top: $header-height;
  }
}

.subhead-content {
  position: relative;
  padding: 1em 0 0.75em;
  background-color: #004170;
  color: $white;
  box-shadow: $box-shadow-primary;
  z-index: 20;
}

.subhead-content.sticky-top {
  position: relative;

  @include media-breakpoint-up(sm) {
    position: static; /* IE11 */
    position: sticky;
    top: $header-height;
  }
}

.list-content {
  position: relative;
  padding-top: 2em;
  z-index: 10;
  padding-bottom: 2em;
}

footer.footer-page {
  height: auto;
  // background-color: $primary;
  // color: $white;

  @include media-breakpoint-up(sm) {
    height: $footer-height;
  }
}

.tabulator {
  font-size: 1em;
  transform: none !important;
  will-change: auto;
  background-color: $white;
  box-shadow: $box-shadow-primary;
}

.tabulator.tabulator-sm {
  font-size: 0.85em;
}

.tabulator.tabulator-sm .tabulator-row {
  min-height: 1em;
}

.tabulator.tabulator-sm .tabulator-row .tabulator-cell {
  padding: 8px;
}

.tabulator.tabulator-sm
  .tabulator
  .tabulator-header
  .tabulator-col
  .tabulator-col-content {
  padding: 8px;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="none"]
  .tabulator-col-content
  .tabulator-col-sorter {
  color: transparent;
}

.tabulator
  .tabulator-header
  .tabulator-col.tabulator-sortable[aria-sort="none"]
  .tabulator-col-content
  .tabulator-col-sorter
  .tabulator-arrow {
  color: transparent;
  border-color: transparent;
}

.tabulator-cell.tabulator-cell-chart {
  padding: 0;
}

.table-bordered > :not(caption) > * > .tabulator-row {
  border-bottom: 1px solid #dee2e6;
}

.inline-flag {
  display: inline-block;
  background-color: transparent;
  background-size: cover;
  width: 24px;
  height: 24px;
  margin-right: 8px;

  &.flag-eu {
    background-image: url(/images/flags/48/EU.png);
    // background-image: url(/images/eu.jpg);
  }

  &.flag-us {
    background-image: url(/images/flags/48/US.png);
    // background-image: url(/images/us.jpg);
  }

  &.flag-europe {
    background-image: url(/images/flags/48/EU.png);
    // background-image: url(/images/us.jpg);
  }

  &.flag-eurozone {
    background-image: url(/images/flags/48/EUZONE.png);
    // background-image: url(/images/us.jpg);
  }

  &.flag-asia {
    background-image: url(/images/flags/48/ASIA.png);
    // background-image: url(/images/us.jpg);
  }

  &.flag-usandeurope {
    background-image: url(/images/flags/48/USEU.png);
    // background-image: url(/images/us.jpg);
  }
}

.card.card-flag {
  background-color: #f7f7f7;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $white;
  }

  &.card-flag-eu > .card-header {
    background-image: url(/images/flags/48/EU.png);
    // background-image: url(/images/eu.jpg);
  }

  &.card-flag-us > .card-header {
    background-image: url(/images/flags/48/US.png);
    // background-image: url(/images/us.jpg);
  }

  &.card-flag-europe > .card-header {
    background-image: url(/images/flags/48/EU.png);
    // background-image: url(/images/us.jpg);
  }

  &.card-flag-eurozone > .card-header {
    background-image: url(/images/flags/48/EUZONE.png);
    // background-image: url(/images/us.jpg);
  }

  &.card-flag-asia > .card-header {
    background-image: url(/images/flags/48/ASIA.png);
    // background-image: url(/images/us.jpg);
  }

  &.card-flag-usandeurope > .card-header {
    background-image: url(/images/flags/48/USEU.png);
    // background-image: url(/images/us.jpg);
  }

  > .card-header {
    background-color: transparent;
    color: #333;
    border-bottom-color: #ccc;
    background-position: left 10px center;
    background-repeat: no-repeat;
    padding-left: 70px;
    min-height: 75px;
    h3 {
      font-size: 1.5em;
    }
  }

  > .card-body {
    padding: 8px;
    background-color: transparent;
    color: #333;

    .card-body {
      background-color: transparent;
    }
  }

  .card-content {
    line-height: 1.4;

    .no-item {
      list-style: none;
    }
  }

  .card {
    background-color: transparent;

    &.card-no-border {
      border: 0 !important;
    }
  }

  .card-item:first-child .card-body {
    border-right: 1px solid #ccc;
  }

  i.card-icon {
    font-size: 1.6em;
    position: absolute;
    right: 12px;
    top: 8px;
    opacity: 0.6;
  }
}

.table-chart-image {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.popover {
  max-width: none;
}

.modal-chart-image {
  display: block;
  max-width: auto;
  height: 70vh;
  max-height: 400px;
}

.dropdown-menu {
  max-height: 70vh;
  overflow-y: scroll;
}

.mainContentColumn.mainContentColumn--left {
  background-color: $white;
  border-right: 2px solid $primary;
}

.product-analytics-charts {
  background-color: $white;
  box-shadow: $box-shadow-primary;
}

.product-analytics-chart {
  flex: 1 1 auto;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.nav-item {
  cursor: pointer;
}

.nav-item[disabled] {
  cursor: default;
}

.ratio-analytics-charts {
  --bs-aspect-ratio: calc(4 / 16 * 100%);
}

.product-analytics-chart-wrap {
  cursor: pointer;
}

.product-detail-title {
  background-color: $primary;
  color: $white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 25vw;
  font-size: 1.5em;
  margin: 0;
  padding: 0;
}

.product-detail-subtitle {
  background-color: $primary;
  color: $white;
  padding: 4px 8px;
  font-size: 1.15em;
}

.product-detail-facts-wrap {
  padding: 1em;
  background-color: $white;
  box-shadow: $box-shadow-primary;
}

.product-detail-facts-wrap-column {
  border-right: 1px solid #dee2e6;
}

.product-detail-facts-wrap-column:last-child {
  border-right: none;
}

.product-detail-facts {
  table-layout: fixed;
  width: 100%;
  font-size: 0.8em;
}

.product-detail-facts td,
.product-detail-facts th {
  text-align: right;
  padding: 3px 5px;
  vertical-align: middle;
  height: 3.5em;
}

.product-detail-facts td:first-child {
  text-align: left;
}

.product-detail-facts-column-label {
}

.product-detail-facts-column-strategy {
  width: 22%;
}

.product-detail-facts-column-benchmark {
  width: 22%;
}

.product-detail-facts-column-difference {
  width: 22%;
}

.form-signin {
  max-width: 800px;
  width: 100%;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.text-md {
  font-size: 0.9em;
  font-weight: bold;
}

.text-small {
  font-size: 0.8em;
}

.text-smaller {
  font-size: 0.7em;
}

.big-button-catalog {
  display: block;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.btn-check {
  &:checked {
    + .btn.btn-filter.btn-outline-light {
      background-color: $white !important;
      color: $black !important;
    }

    &:focus {
      + .btn.btn-filter.btn-outline-light {
        background-color: $white !important;
        color: $black !important;
      }
    }
  }

  &:focus {
    + .btn.btn-filter.btn-outline-light {
      background-color: inherit !important;
      color: inherit !important;
    }
  }
}

.header-user {
  height: auto;
  font-size: 0.8em;

  &.active.btn-primary,
  &:active.btn-primary,
  &.active:focus,
  &:active:focus {
    background-color: $primary !important;
    border-color: $primary !important;
    color: $white !important;
  }

  @include media-breakpoint-up(sm) {
    height: $header-height;
    font-size: 1em;
  }
}

.header-user-box {
  position: fixed;
  padding: 20px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 900;
  background-color: $primary;
  height: 100%;
  overflow: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: $white;
  }

  a:not(.btn) {
    color: $white;

    &:hover {
      color: darken($color: $white, $amount: 10);
    }
  }

  @include media-breakpoint-up(sm) {
    position: absolute;
    bottom: auto;
    top: $header-height;
    height: auto;
    overflow: visible;
  }
}

.app-wrap {
  &.min-full-height {
    min-height: 0;
  }

  &.full-height {
    height: auto;
  }

  @include media-breakpoint-up(md) {
    &.min-full-height {
      min-height: 100vh;
    }

    &.full-height {
      height: 100vh;
    }
  }
}

.selectable-item {
  cursor: pointer;
}

/* Authenticated */

.app-lock {
  position: relative;
}

.app-lock-clickable {
  cursor: pointer;
}

.browser-ie .app-lock {
  min-height: 30px;
  background-color: rgba(0, 0, 0, 0.2);
}

.app-lock-table {
  position: relative;
  max-height: 240px;
  pointer-events: none;
  overflow: hidden;
}

.app-lock-table:after {
  position: absolute;
  display: block;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.app-lock-content {
  position: relative;
  opacity: 0.6;
  filter: blur(4px);
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.browser-ie .app-lock-content {
  display: none !important;
}

.app-lock-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.2);
  color: #000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-hover {
  transform: scale(1);
  transition: all 0.3s ease-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.img-block {
  display: block;
  max-width: 100%;
  height: auto;
}

.validation-error-message {
  p {
    margin-bottom: 0px;
    margin-top: 5px;
    color: #ff0000;
    font-size: 14px;
  }
}

.input-error-state {
  border: 2px solid #ff0000;

  &:focus {
    border: 2px solid #ff0000;
  }
}

.input-label {
  margin-bottom: 5px;
}

.valid-state {
  border: 2px solid #77dd77;

  &:focus {
    border: 2px solid #77dd77;
  }
}

.success-message-wrapper {
  width: 100%;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__icon {
    font-size: 150px;
    color: #77dd77;
  }

  &__message {
    display: flex;
    align-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;

    p {
      margin-top: 20px;
      width: 50%;
      align-self: center;
    }
  }
}

.table-compact {
  margin-top: 15px;
  width: 100%;
  table-layout: fixed;
  font-size: 0.8em;

  tr:first-child {
    border-bottom: 1px solid #ddd;
  }

  th,
  td {
    padding: 3px 4px;
    text-align: right;

    &:first-child {
      text-align: left;
    }
  }
}

.table-small-width {
  max-width: 1000px;
  width: 100%;
  table-layout: auto;
  font-size: 1.2em;
  vertical-align: middle;

  // tr:first-child {
  //     border-bottom: 1px solid #ddd;
  // }
}
