/*
* Author  : Stefano Mondini <s.mondini@trendrating.net>
* Created : 4 jun 2019
*
* Styles for new Security Analysis page
*/

.tPageAnalysisSecurity {
    font-weight: normal;
}

.tPageAnalysisSecurity .dijitTabController {
    margin-bottom: 20px;
}

.claro .tPageAnalysisSecurity .dijitTabContainerTabListNested .dijitTab {
    transition-property: none;
    min-width: 0;
}

.claro
    .tPageAnalysisSecurity
    .dijitTabContainerNested
    .dijitTabContainerTop-tabs {
    border-bottom-color: #2a7092;
}

.claro
    .tPageAnalysisSecurity
    .dijitTabContainerNested
    .dijitTabContainerTop-tabs
    .dijitTab {
    padding: 6px 8px;
}

.claro
    .tPageAnalysisSecurity
    .dijitTabContainerNested
    .dijitTabContainerTop-tabs
    .dijitTab:focus,
.claro
    .tPageAnalysisSecurity
    .dijitTabContainerNested
    .dijitTabContainerTop-tabs
    .dijitTab
    .tabLabel:focus {
    outline: none;
}

.claro
    .tPageAnalysisSecurity
    .dijitTabContainerNested
    .dijitTabContainerTop-tabs
    .dijitTab:hover {
    background-color: #ddd;
}

.claro
    .tPageAnalysisSecurity
    .dijitTabContainerTabListNested
    .dijitTabChecked
    .tabLabel {
    outline: none;
}

.tPageAnalysisSecurity .dijitTabDisabled {
    display: none;
}

.tPageAnalysisSecurity-content {
    padding: 15px;
    border: 1px solid #ccc;
    background-color: #fff;
    min-height: 80vh;
}

.tPageAnalysisSecurity .search {
    width: 100%;
}

.tPageAnalysisSecurity-search {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em;
    display: block;
    font-size: 150%;
}

/* Header */
.tSecurityAnalysisHeader {
    margin-bottom: 1.5em;
    padding: 0;
    background-color: #fff;
    color: #000;
    border-bottom: 1px solid #ccc;
}

.tSecurityAnalysisHeader .tSecurityAnalysisHeader-content {
    display: flex;
    margin: 0 1em 1em;
    align-items: stretch;
}

.tSecurityAnalysisHeader-name {
    margin-bottom: 0.5em;
    font-size: 1.7em;
    font-weight: 600;
}

.tSecurityAnalysisHeader-price {
    text-transform: uppercase;
    font-weight: 600;
}

.tSecurityAnalysisHeader-since {
    padding-left: 0.5em;
}

.tSecurityAnalysisHeader-rate {
    display: inline-block;
    font-size: 2.8em;
    font-weight: 600;
    line-height: 1.1;
}

.tSecurityAnalysisHeader-rateDate {
    display: inline-block;
    padding-left: 0.5em;
    font-weight: 600;
}

.tSecurityAnalysisHeader-momentum {
    font-size: 1.5em;
    font-weight: 600;
}

.tSecurityAnalysisHeader-retracement {
    font-size: 1.5em;
    font-weight: 600;
}

.tSecurityAnalysisHeader-newHighLow {
    padding-left: 3px;
}

.tSecurityAnalysisHeader-cell {
    flex: 1 1 auto;
    padding: 10px 5px 5px 10px;
    border-left: 1px solid #ccc;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tSecurityAnalysisHeader-cell-label {
}

.tSecurityAnalysisHeader-cell-content {
}

.tSecurityAnalysisHeader-cell-rate {
    flex: 1 1 120px;
}

.tSecurityAnalysisHeader-cell-momentum {
    flex: 1 1 100px;
    text-align: right;
}

.tSecurityAnalysisHeader-cell-retracement {
    flex: 1 1 100px;
    text-align: right;
}

/* Widgets */

.wSecurityAnalysisChart {
    border: 1px solid #ccc;
    background: #fff;
}

.wSecurityAnalysisChart-chartLoader {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.wSecurityAnalysisChart-chartWrap {
    position: relative;
}

.wSecurityAnalysisChart-chart {
    opacity: 1;
    transition: opacity 0.5s ease-out;
}

.wSecurityAnalysisChart-rangeSelector {
    margin-right: 1em;
    position: relative;
}

.wSecurityAnalysisChart .wSecurityAnalysisChart-bar {
    display: flex;
    flex-wrap: nowrap;
    padding: 0.5em;
}

.wSecurityAnalysisChart .wSecurityAnalysisChart-bar-left {
    flex: 1 1 200px;
    padding-right: 1em;
}

.wSecurityAnalysisChart .wSecurityAnalysisChart-bar-right {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }
}

.wSecurityAnalysisChart-action {
    margin: 0 0 0 2em;
    position: relative;
    top: 2px;
}

/* 
    tPriceChartInfo widget
*/
.tPriceChartInfo {
    padding: 0 15px 15px;
    min-height: 110px;
}

.tPriceChartInfo .dgrid {
    border: none;
}

.claro .tPriceChartInfo .dgrid-header .dgrid-cell {
    text-align: left;
}

.claro .tPriceChartInfo .dgrid-header .dgrid-cell {
    text-align: left;
}

.tPriceChartInfo .dgrid .dgrid-cell {
    padding: 0.5em 0.25em;
    text-align: right;
    vertical-align: middle;
}

.tPriceChartInfo .dgrid .dgrid-cell.field-vc {
    text-align: left;
}

.tPriceChartInfo td {
    width: auto;
}

.tPriceChartInfo td.field-performance {
    font-weight: 600;
}

.tPriceChartInfo th {
    border-top: 1px solid #ccc;
}

.tPriceChartInfo .field-price {
    text-align: right;
}

.claro .tPriceChartInfo .dgrid-header th.dgrid-cell.field-currentDate {
    text-align: center;
}

.tPriceChartInfo th.dgrid-cell.field-ticker {
    border-top-color: transparent;
    border-left-color: transparent;
}

.tPriceChartInfo-small {
    font-size: 0.9em;
    font-weight: normal;
}

/* More generic dgrid rules */

.tPriceChartInfoCommon .dgrid .dgrid-cell.field-ticker {
    padding-left: 8px;
    font-weight: 600;
    text-align: left;
}

.tPriceChartInfoCommon .dgrid .dgrid-cell.field-ticker .field-ticker-indicator {
    display: inline-block;
    width: 3px;
    height: 10px;
    position: relative;
    left: 0px;
    top: 1px;
}

.tPriceChartInfoCommon .dgrid th.dgrid-cell.field-datePerf {
    text-align: center;
}

.tPriceChartInfoCommon .dgrid .dgrid-cell.field-priceFrom {
    text-align: left;
}

.tPriceChartInfoCommon .dgrid .dgrid-cell.field-priceTo {
    text-align: left;
}

/* 
    tFormSearchCheckbox widget
*/
.tFormSearchCheckbox {
    margin-bottom: 0.5em;
}

.tFormSearchCheckbox .tFormSearchCheckbox-toggle {
    display: flex;
    flex-wrap: nowrap;
}

.tFormSearchCheckbox .tFormSearchCheckbox-label {
    cursor: pointer;
    position: relative;
    top: 2px;
    border-bottom: 0.25em solid transparent;
    border-bottom-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 160px;
    overflow: hidden;
    display: block;
}

.tFormSearchCheckbox .tFormSearchCheckbox-checkbox {
    display: none;
}

.tFormSearchCheckbox
    .tFormSearchCheckbox-checkbox:checked
    + .tFormSearchCheckbox-label {
    border-bottom-color: currentColor;
    color: #2a7092;
}

.tFormSearchCheckbox .tFormSearchCheckbox-toggle button {
    margin-left: 10px;
}

.tFormSearchCheckbox .tFormSearchCheckbox-search {
    display: none;
}

.tFormSearchCheckbox.isSearching .tFormSearchCheckbox-toggle {
    display: none;
}

.tFormSearchCheckbox.isSearching .tFormSearchCheckbox-search {
    display: block;
}

@media all and (min-width: 1025px) {
    .tPageAnalysisSecurity {
        max-width: 100em;
        margin: 0 auto;
    }
}

/*******************************************************************************************
    Generic widgets
*/

.tSecurityAnalysis-headerWrap {
    display: flex;
    width: 100%;
    margin-bottom: 2px;
    align-items: baseline;
}

.tSecurityAnalysis-headerWrap-main {
    flex: 1 1 auto;
    text-align: left;
}

.tSecurityAnalysis-headerWrap-main h1.tSecurityAnalysis-header {
    display: inline-block;
}

.tSecurityAnalysis-headerWrap-side {
    flex: 0 1 auto;
    text-align: right;
}

/**************************************************
    Header title h1
*/
h1.tSecurityAnalysis-header--primary {
    margin-bottom: 1em;
}

.tSecurityAnalysis-header-icon {
    color: #2a7092;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    padding: 0.5em 0;
}

.tSecurityAnalysis-header-icon:hover {
    text-decoration: none;
}

.tSecurityAnalysis-wrap {
    border: 1px solid #ccc;
    background-color: white;
    padding: 15px;
}

.tSecurityAnalysis-wrapTitle {
    margin-bottom: 8px;
}

.tSecurityAnalysis-wrapTitle.tSecurityAnalysis-wrapTitle--inline {
    display: inline-block;
}

/*******************************************************************************************
    Market widgets
*/

/**************************************************
    Market widget: title selector
*/

[dijitpopupparent$="-tSecurityAnalysisWhatWherePeer"].tLayoutDropDownPopup {
    width: 300px;
}

[dijitpopupparent$="-tSecurityAnalysisWhatWherePeer"].tLayoutDropDownPopup
    .tLayoutDropDown {
    max-height: 35em;
}

[dijitpopupparent$="-tSecurityAnalysisWhatWherePeer"].tLayoutDropDownPopup
    .tLayoutDropDownItem {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    padding-left: 2em;
    cursor: pointer;
}

[dijitpopupparent$="-tSecurityAnalysisWhatWherePeer"].tLayoutDropDownPopup
    .tLayoutDropDownItem.tLayoutDropDownItem--separator {
    display: none;
}

[dijitpopupparent$="-tSecurityAnalysisWhatWherePeer"].tLayoutDropDownPopup
    .tLayoutDropDownItem:first-child {
    padding-left: 0.5em;
    color: #aaa;
    font-style: italic;
    cursor: default;
    pointer-events: none;
}

[dijitpopupparent$="-tSecurityAnalysisWhatWherePeer"].tLayoutDropDownPopup
    .tLayoutDropDownItem:first-child:hover {
    background-color: #fff;
}

[dijitpopupparent$="-tSecurityAnalysisWhatWherePeer"].tLayoutDropDownPopup
    .tLayoutDropDownItem--separator
    + .tLayoutDropDownItem {
    padding-left: 0.5em;
}

/**************************************************
    Market widget: TCR
*/

.tSecurityAnalysisTcr .tSecurityAnalysisTcr-value {
    font-size: 320%;
}

/**************************************************
    Market widget: Rating distribution
*/

.tSecurityAnalysisRatingDistribution .tFormSelect {
    padding: 0 0 0 2px;
}

.tSecurityAnalysisRatingDistribution-distributions {
    display: flex;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: center;
    padding: 1em;
    text-align: center;
}

.tSecurityAnalysisRatingDistribution-distribution {
    flex: 1 1 auto;
    font-size: 150%;
}

.tSecurityAnalysisRatingDistribution-distribution strong {
    flex: 1 1 auto;
    font-size: 125%;
}

.tSecurityAnalysisRatingDistribution-distributionValue,
.tSecurityAnalysisRatingDistribution-distributionWeight {
    margin-left: 0.2em;
    margin-right: 0.2em;
}

.tSecurityAnalysisRatingDistribution-distributionValue {
    display: inline-block;
}

.tSecurityAnalysisRatingDistribution-distributionWeight {
    display: none;
}

.tSecurityAnalysisRatingDistribution.showWeight
    .tSecurityAnalysisRatingDistribution-distributionValue {
    display: none;
}

.tSecurityAnalysisRatingDistribution.showWeight
    .tSecurityAnalysisRatingDistribution-distributionWeight {
    display: inline-block;
}

/**************************************************
    Market widget: Performance Distribution
*/

.tSecurityAnalysisPerformanceDistribution .tTable {
    table-layout: fixed;
    width: 100%;
}

.tSecurityAnalysisPerformanceDistribution .tTable thead th {
    font-weight: 600;
    padding: 10px 0 10px;
}

.tSecurityAnalysisPerformanceDistribution .tTable td,
.tSecurityAnalysisPerformanceDistribution .tTable th {
    padding: 3px 0;
}

.tSecurityAnalysisPerformanceDistribution .tTable .tTable-cell-label {
    padding: 3px 5px;
}

.tSecurityAnalysisPerformanceDistribution .tTable .tTable-header-label {
    width: 25%;
}

.tSecurityAnalysisPerformanceDistribution .tTable .tTable-header-ticker {
    width: 25%;
}

.tSecurityAnalysisPerformanceDistribution .tTable .tTable-header-min {
    width: 52px;
    text-align: right;
}

.tSecurityAnalysisPerformanceDistribution .tTable .tTable-header-market {
    width: 210px;
    text-align: center;
}

.tSecurityAnalysisPerformanceDistribution .tTable .tTable-header-max {
    width: 52px;
    text-align: left;
}

/**************************************************
    Market widget: Peer rank ranking template select
*/

.tSecurityAnalysisRankingTemplate {
    display: inline-block;
    margin-left: 0.5em;
}

/**************************************************
    Alternatives widget: Alternative container and items
*/

.tSecurityAnalysisAlternative-infoBox {
    font-size: 1.3em;
    text-align: center;
    color: #666;
}

/**************************************************
    Other widgets
*/

.tSecurityAnalysisTabMarket .tSecurityAnalysisTabMarket-loader {
    display: none;
    font-size: 0.9em;
}

@keyframes blink {
    0% {
        opacity: 0.2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: 0.2;
    }
}

.tSecurityAnalysisTabMarket-wrap {
    color: #2a7092;
}

.tSecurityAnalysisTabMarket .tSecurityAnalysisTabMarket-loader span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.tSecurityAnalysisTabMarket
    .tSecurityAnalysisTabMarket-loader
    span:nth-child(2) {
    animation-delay: 0.2s;
}

.tSecurityAnalysisTabMarket
    .tSecurityAnalysisTabMarket-loader
    span:nth-child(3) {
    animation-delay: 0.4s;
}

.tSecurityAnalysisTabMarket.isLoading .tSecurityAnalysisTabMarket-loader {
    display: inline-block;
}

.tSecurityAnalysisTabMarket-peer,
.tSecurityAnalysisTabMarket-peer strong {
    font-weight: 600;
}

.tSecurityAnalysisTabMarket-filters {
    margin-bottom: 15px;
}

.tSecurityAnalysisTabMarket-filter {
    display: inline-block;
    margin-right: 1em;
}

.tSecurityAnalysisTabMarket-filter .tFormToggle-label {
    position: relative;
    top: 2px;
}

.tSecurityAnalysisTabMarket-size {
    font-size: 0.7em;
    display: inline-block;
    margin-left: 0.5em;
}

.tSecurityAnalysisTabMarket-commonStockInfo {
    color: #999;
    margin-top: 10px;
    font-size: 1.1em;
}

.tSecurityAnalysisAlternatives {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.tSecurityAnalysisAlternative {
    background-color: #fff;
    color: #000;
    margin-bottom: 0.5em;
    border: 1px solid #ccc;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    min-height: 245px;
}

.tSecurityAnalysisAlternative-logo {
    display: inline-block;
    vertical-align: middle;
}

.tSecurityAnalysisAlternative-name {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.5em;
    margin-bottom: 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
}

.tSecurityAnalysisAlternative-logo {
    background-image: none;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    float: right;
    height: 64px;
    outline: 1px solid #ccc;
    width: 64px;
}

.tSecurityAnalysisAlternative-info {
    align-self: center;
    padding-bottom: 15px;
}

.tSecurityAnalysisAlternative-type-and-market {
    margin-bottom: 0.5em;
    font-size: 1.1em;
    line-height: 1.6;
}

.tSecurityAnalysisAlternative-rating {
    font-size: 1.2em;
}

.tSecurityAnalysisAlternative-rating .rate,
.tSecurityAnalysisAlternative-rating .format-rate {
    font-size: 1.4em;
}

.tSecurityAnalysisAlternative-price {
    text-transform: uppercase;
    font-size: 1.2em;
}

.tSecurityAnalysisAlternative-chart {
    display: block;
    max-width: 100%;
    height: auto;
}

/**************************************************
    Compare page and widgets
*/

.tSecurityAnalysisTabCompare-tLayout-large {
    flex: 1 1 100%;
    margin-left: 15px;
}

.tSecurityAnalysisTabCompare-tLayout-small {
    flex: 1 0 auto;
}

.tSecurityAnalysisTabCompare-compare {
    display: flex;
    justify-content: left;
    flex: 0 1 auto;
    padding: 0;
    margin-top: 8px;
}

.tSecurityAnalysisTabCompare-compare
    .wSecurityAnalysisTabCompareItem:first-child
    .wSecurityAnalysisTabCompareItem-itemLabel {
    visibility: visible;
}

.tSecurityAnalysisTabCompare-chart {
    max-width: 100%;
}

/* Highchart Bug Fix for auto resize */
.tSecurityAnalysisTabCompare-chart .highcharts-container {
    width: 100% !important;
}

.tSecurityAnalysisTabCompare-chart .highcharts-container svg {
    max-width: 100% !important;
    height: 100% !important;
}

.wSecurityAnalysisTabCompareItem-itemLabel {
    visibility: hidden;
    font-size: 0.9em;
}

.wSecurityAnalysisTabCompareItem-itemLabel
    .wSecurityAnalysisTabCompareItem-itemLabel-small {
    font-size: 0.9em;
    font-weight: normal;
}

.wSecurityAnalysisTabCompareItem {
    flex: 1 0 85px;
    width: 85px;
    background-color: #fff;
}

.wSecurityAnalysisTabCompareItem--small {
    padding-top: 5px;
    flex: 1 0 40px;
    width: 40px;
}

.wSecurityAnalysisTabCompareItem-action {
    margin-left: 10px;
}

.wSecurityAnalysisTabCompareItem-action:hover {
    text-decoration: none;
}

.wSecurityAnalysisTabCompareItem-action-icon {
    display: inline-block;
    font-size: 1.8em;
    line-height: 1;
    font-weight: normal;
    color: #2a7092;
    transform: rotate(45deg);
}

.wSecurityAnalysisTabCompareItem.wSecurityAnalysisTabCompareItem-legend {
    flex: 1 0 105px;
    background-color: transparent;
    font-size: 1.1em;
    font-weight: 600;
    width: 105px;
}

.wSecurityAnalysisTabCompareItem.wSecurityAnalysisTabCompareItem-legend
    .wSecurityAnalysisTabCompareItem-item {
    text-align: left;
}

.wSecurityAnalysisTabCompareItem.wSecurityAnalysisTabCompareItem-legend
    .wSecurityAnalysisTabCompareItem-item.wSecurityAnalysisTabCompareItem-item-ticker {
    border-top-color: transparent;
    border-left-color: transparent;
}

.wSecurityAnalysisTabCompareItem.wSecurityAnalysisTabCompareItem-legend
    .wSecurityAnalysisTabCompareItem-item.wSecurityAnalysisTabCompareItem-item-momentum {
    white-space: normal;
}

.tSecurityAnalysisTabCompare-itemDetails {
    padding-top: 1em;
}

.tSecurityAnalysisTabCompare-itemDetail {
    font-weight: normal;
}

.flashing {
    -webkit-animation: flashingWidget 2s normal forwards;
    -moz-animation: flashingWidget 2s normal forwards;
    animation: flashingWidget 2s normal forwards;
}

@keyframes flashingWidget {
    0% {
        background-color: #fff;
    }
    30% {
        background-color: #333;
    }
    80% {
        background-color: #fff;
    }
    100% {
        background-color: #fff;
    }
}

.wSecurityAnalysisTabCompareItem-items {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.wSecurityAnalysisTabCompareItem-spacer {
    height: 25px;
}

.wSecurityAnalysisTabCompareItem-item {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;

    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 24px;
    height: 24px;
    overflow: hidden;
    line-height: 1.4;
    white-space: nowrap;
}

.wSecurityAnalysisTabCompareItem-item.wSecurityAnalysisTabCompareItem-item-ticker {
    flex: 1 1 33px;
    height: 33px;
}

.wSecurityAnalysisTabCompareItem-item.wSecurityAnalysisTabCompareItem-item-industry {
    white-space: normal;
}

.wSecurityAnalysisTabCompareItem-item.wSecurityAnalysisTabCompareItem-item--doubleHeight {
    flex: 1 1 44px;
    height: 44px;
}

.wSecurityAnalysisTabCompareItem-item.wSecurityAnalysisTabCompareItem-item--alignRight {
    text-align: right;
}

.wSecurityAnalysisTabCompareItem-itemAction {
    position: absolute;
    display: inline-block;
    color: #ffffff;
    padding: 0;
    top: 3px;
    right: 2px;
    line-height: 10px;
    border-bottom: none;
    z-index: 10;
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.3s ease-in-out;
}

.wSecurityAnalysisTabCompareItem-itemAction:hover {
    opacity: 1;
}

.wSecurityAnalysisTabCompareItem-itemContent-industry {
    word-break: break-word;
}

.wSecurityAnalysisTabCompareItem-itemContent-ticker {
    font-size: 105%;
    font-weight: 600;
}

.wSecurityAnalysisTabCompareItem-itemContent-ticker sup {
    font-size: 0.8em;
    font-weight: normal;
}

.wSecurityAnalysisTabCompareItem-itemContent-rate {
    border-bottom: none;
    font-size: 150%;
    line-height: 1.1;
    font-weight: 600;
}

/* CompareItem table borders */

.wSecurityAnalysisTabCompareItem-item {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.wSecurityAnalysisTabCompareItem-item:last-child {
    border-bottom: 1px solid #ccc;
}

.wSecurityAnalysisTabCompareItem-item.wSecurityAnalysisTabCompareItem-item--header {
    padding-top: 10px;
    padding-left: 0;
    border-left-color: transparent;
    text-align: left;
}

.wSecurityAnalysisTabCompareItem.last .wSecurityAnalysisTabCompareItem-item {
    border-right: 1px solid #ccc;
}

.wSecurityAnalysisTabCompareItem.last
    .wSecurityAnalysisTabCompareItem-item.wSecurityAnalysisTabCompareItem-item--header {
    border-right-color: transparent;
}

.tSecurityAnalysisTabHistorical .wSecurityAnalysisChart-chart {
    width: 100%;
    height: 50vh;
}

.tSystematicProductChart {
    height: 100%;
}

.tSystematicProductChart-chart {
    height: 100%;
    padding: 0;
}

.tSystematicProductChart .wSystematicProductChart {
    height: 100%;
    padding: 0;
}

.tSystematicProductChart .wSecurityAnalysisChart {
    height: 100%;
    padding: 0;
}

.tSystematicProductChart .wSecurityAnalysisChart-chartWrap {
    height: calc(100% - 3em);
}

.tSystematicProductChart .wSecurityAnalysisChart-chart {
    height: calc(100% - 3em);
}

.tSystematicProductChart .wSystematicProductChart-chart {
    height: 100%;
}
