/*
 *
 * Author: Trendrating <info@trendrating.net>
 *
 * Formatter styles
 *
 */
.format {
    display: inline-block;
}

.format-alert {
    /* alert arrows up/down and new notification */
    color: #fb0;
}

.format-bar {
    display: inline-block;
    height: 1em;
    line-height: 0;
    margin-left: 0.5em;
    outline: 1px solid #d3d3d3;
    overflow: hidden;
    text-align: left;
}

@media screen and (max-width: 1024px) {
    .format-bar {
        /* it needs important because width is set by JS */
        width: 1.25em !important;
    }
}

@media screen and (min-width: 1025px) {
    .format-bar {
        /* it needs important because width is set by JS */
        width: 2.25em !important;
    }
}

@media screen and (min-width: 1300px) {
    .format-bar {
        /* it needs important because width is set by JS */
        width: 3.25em !important;
    }
}

.format-barBlock {
    display: inline-block;
    height: 1em;
}

.format-number {
}

.format-number--negative {
    color: #f00000;
}

.format-number--positive {
    color: #008000;
}

.format-rate {
    color: #000;
    font-weight: 600;
}

.format-rate--A {
    color: #008000;
}

.format-rate--B {
    color: #8bbc00;
}

.format-rate--C {
    color: #f48400;
}

.format-rate--D {
    color: #f00000;
}

.format-rate--U {
    color: #000;
}
